import React, { useState} from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useQueryParam, StringParam } from "use-query-params"

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'


import imgSuccess from "../../images/icon-success.jpg"
import imgFail from "../../images/icon-fail.jpg"


const UserFormInput = initialValue => {
    const [value, setValue] = useState(initialValue)
    const handleChange = e => {
        setValue(e.target.value)
    }
    return {
        value,
        onChange: handleChange,
    }
}

export default function ContactForm (props) {
    

    const [notification, setNotification] = useState('')
    const [isSend, setIsSend] = useState(false)

    const { executeRecaptcha } = useGoogleReCaptcha()
    
    let comercio = UserFormInput('')
    let nombre = UserFormInput('')
    let telefono = UserFormInput('')
    let email = UserFormInput('')

    const cVal = comercio.value
    const nVal = nombre.value
    const tVal = telefono.value
    const eVal = email.value
    const [utm_campaign, setUtm_campaign] = useQueryParam("utm_campaign", StringParam)
    const [utm_medium, setUtm_mediun] =  useQueryParam("utm_medium", StringParam)
    const [utm_source, setUtm_source] = useQueryParam("utm_source", StringParam)
       
    const handleSubmit = async e => {
        e.preventDefault()

        setIsSend(true);

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            setNotification('error')
            return
        }
                
        // This is the same as grecaptcha.execute on traditional html script tags
        const token = await executeRecaptcha('homepage')
        
        // Prepare the data for the server, specifically body-parser
        const data = JSON.stringify({ cVal, nVal, tVal, eVal, utm_campaign, utm_medium, utm_source, token })             


        // POST request to your server
        fetch('https://us-central1-nodo-federal.cloudfunctions.net/apinodo/submitForm', {          
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Accept': 'application/json, text/plain, *//*',
              'Content-type': 'application/json'
            },
            body: data 
        })
        .then(res => res.json())
        .then(json => {            
            setIsSend(false)
            setNotification(json.msg)
        })
        
    }

    return (
     
      <section className="overlay">
        <div className="container main py-lg-3">
          <div className="row align-items-center h-100 py-lg-5">
            {/* First Column */}
            <div className="col-lg-7 info">
              <h1>Servicio de informes comerciales y financieros</h1>
              <p>Obtené en segundos  y en el momento que lo necesites información 
                que permita minimizar riesgos financieros y comerciales. Con un solo 
                clic accederás a un reporte detallado y actualizado con información 
                relevante que incluye datos personales, reporte de BCRA, morosidad, 
                bienes registrados, situación laboral y financiera de tus asociados.
              </p>              
              <Accordion className="accordion" id="accordionExample">
                    <Card className="card">                
                        <Accordion.Toggle as={Card.Header} eventKey="0" className="card-header" id="headingOne">
                            <h2 className="mb-0">
                            <a className="btn btn-link btn-block text-left btn-expand collapsed pl-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                              <span className="icon" style={{float: 'right'}}>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                                  <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                                </svg>
                              </span>
                              Contamos con afectación con notificación electrónica 
                            </a>
                            </h2>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body">
                            A través de este servicio informamos situación de mora a los deudores por
                            correo electrónico, mensaje de texto y Whatsapp, lo cual permite mejorar la
                            eficiencia en la receptividad además de reducir costos operativos.
                            </div>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
            {/* Form Column */}                      
            <div className="col-lg-5 colfom px-lg-3 px-0 animate__animated animate__fadeIn">   
             {
              notification == '' && 
                  <Form id="landing-form" className="p-5" onSubmit={handleSubmit}>
                    <h4>Dejanos tus datos y en 24 hs hábiles accedé a un informe gratuito</h4>                            
                    <Form.Group controlId="comercio" className="form-group">                                
                        <Form.Control className="form-control"
                            type="text"
                            name="comercio"                            
                            {...comercio}
                            placeholder="Comercio/Organismo"                    
                            required/>                        
                    </Form.Group>                            
                    <Form.Group controlId="nombre" className="form-group">                                
                        <Form.Control className="form-control"
                            type="text"
                            name="nombre"
                            {...nombre}
                            placeholder="Apellido y Nombres"                    
                            required/>                        
                    </Form.Group>                        
                    <Form.Group controlId="telefono" className="form-group">                                
                        <Form.Control className="form-control"
                            type="number"
                            name="telefono"
                            {...telefono}
            
                            placeholder="Teléfono"                    
                            required/>                        
                    </Form.Group>                              
                    <Form.Group controlId="email" className="form-group">
                        <Form.Control className="form-control"
                            type="email"
                            name="email"
                            {...email}
                            placeholder="Email"                    
                            required/>                        
                    </Form.Group>
                    <Button type="submit" disabled={isSend} className="btn btn-primary btn-lg btn-block">Registrarse</Button>                    
                  </Form>
                }
                
                {/* Success */}
                {
                  notification == 'success' &&
                  <div className="centro-v5 p-5 text-center">
                    <img src={imgSuccess} className="true" />
                    <h4 className="pt-4">Muchas gracias por registrarse,<br />
                      nos contactaremos en la brevedad</h4>
                  </div>
                }

                {/* Error */}
                {
                  notification == 'error'  && 
                  <div className="centro-v5 p-5 text-center">
                    <img src={imgFail} className="true" />
                    <h4 className="text-alert pt-4">No se ha podido completar la <br />
                      solicitud. Inténtelo más tarde</h4>
                  </div>
                }              
            </div> 
            
          </div>
        </div>
      </section>      

    )

}