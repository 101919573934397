import React from "react"

import Layout from "../components/layout/layout"
import ContactForm from "../components/contactForm/index"
import SEO from "../components/seo/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Nodo Federal" />    
    <ContactForm />    
  </Layout>
)

export default IndexPage
